.nav-logo {
	height: 3.5rem;
}

.navbar {
	z-index: 10;
}

.ReactModalPortal {
	z-index: 100;
}

/* .ril-outer {
    background-color: #202020 !important;
} */


.scroll-down {
	background-color: transparent;
	border: none;
	display: block;
	font-size: 0px;

}

.scroll-down span {
	position: absolute;
	bottom: 10px;
	left: 50%;
	width: 24px;
	height: 24px;
	margin-left: -12px;
	border-left: 1px solid #fff;
	border-bottom: 1px solid #fff;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-animation: animacija 2s infinite;
	animation: animacija 2s infinite;
	opacity: 0;
	box-sizing: border-box;
	cursor: pointer;
	z-index: 200;
}

.scroll-down span:nth-of-type(1) {
	-webkit-animation-delay: .3s;
	animation-delay: .3s;
}

.scroll-down span:nth-of-type(2) {
	bottom: 20px;
	-webkit-animation-delay: .15s;
	animation-delay: .15s;
}

.scroll-down span:nth-of-type(3) {
	bottom: 30px;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

@-webkit-keyframes animacija {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes animacija {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}


.filter::after {
	background-color: rgba(0, 0, 0, 0.4) !important;
}

.scroll-text {
	position: relative;
	bottom: 60px;
}


.scroll-right span {
	position: absolute;
	right: 40px;
	width: 24px;
	height: 24px;
	border-right: 1px solid #fff;
	border-bottom: 1px solid #fff;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	opacity: 0.4;
	box-sizing: border-box;
	cursor: pointer;
	z-index: 200;
}

.scroll-right span:hover {
	opacity: 1;
}

.scroll-left span {
	position: absolute;
	left: 40px;
	width: 24px;
	height: 24px;
	border-top: 1px solid #fff;
	border-left: 1px solid #fff;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	opacity: 0.4;
	box-sizing: border-box;
	cursor: pointer;
	z-index: 200;
}

.scroll-left span:hover {
	opacity: 1;
}

.patrik-img:hover {
	opacity: .9;
	background-color: black;
}

.Typewriter {
	font-size: 2em;
	z-index: 2;
	color: rgba(255, 255, 255, 0.5);
}


@keyframes fadeInImg {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

/* .LazyLoad {
	opacity: 0;
	width: 100%;
	height: auto;
}

.LazyLoad.is-visible {
	-webkit-animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
	-moz-animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
	-o-animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
	animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
	position: relative;
	opacity: 0;
	animation-fill-mode: forwards;
	animation-duration: 0.7s;
	animation-delay: 0.1s;
} */

.dropdown-menu {
	background: #ffffff !important;
}

.dropdown-item.active {
	background-color: #1b75bc;
	color: #ffffff !important;
}

.navbar-transparent .nav-logo {
	filter: brightness(1000) saturate(0) contrast(1000);
	transition: 0.4s;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
}

.profile-photo {
	width: 100%;
}

.card {
	transition: none !important;
}