// @import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.card-columns {
  @include media-breakpoint-only(xl) {
    column-count: 4 !important;
  }

  @include media-breakpoint-only(lg) {
    column-count: 4 !important;
  }

  @include media-breakpoint-only(md) {
    column-count: 3 !important;
  }

  @include media-breakpoint-only(sm) {
    column-count: 2 !important;
  }

  @include media-breakpoint-only(xs) {
    column-count: 1 !important;
  }

}

.card-img:hover {
  cursor: pointer;
}

.card img {
  border-radius: 0 !important;
}
