@media (min-width: 1200px) {
  .card-columns {
    column-count: 4 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .card-columns {
    column-count: 4 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .card-columns {
    column-count: 3 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .card-columns {
    column-count: 2 !important;
  }
}
@media (max-width: 575.98px) {
  .card-columns {
    column-count: 1 !important;
  }
}

.card-img:hover {
  cursor: pointer;
}

.card img {
  border-radius: 0 !important;
}